import React from 'react';
import {UserContext} from '../context/user-context';
import {getAllQuestionsFromArticleAPI} from '../api/adminApi';

/**
 * Component SelectOrAddQuestion
 * 
 * Properties:
 * - articleId:  The id of the article
 * - currentQuestionId: Currently selected question
 * - onCancel:   Event handler for canceling the action
 * - onSelect:   Event handler for selected an existing question (Param: questionId)
 * - onAdd:      Event handler for adding a new question(Param: question sentence)
 * - onLogout:   Event handler for notifying about a session logout
 */
export default class SelectOrAddQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            newQuestion: ''
        };
        this.handleAddNewQuestion = this.handleAddNewQuestion.bind(this);
        this.handleChangeNewQuestion = this.handleChangeNewQuestion.bind(this);
    }

    componentDidMount() {
        getAllQuestionsFromArticleAPI(
            this.props.articleId,
            this.context.user.token,
            (data) => {
                this.setState({
                    questions: data,
                    newQuestion: ''
                });
            },
            () => {this.props.onLogout()});
    }

    handleAddNewQuestion(event) {
        this.props.onAdd(this.state.newQuestion);
        event.preventDefault();
    }

    handleOpenComesFromAnswers(questionId) {
        this.setComesFromAnswersBoxState(questionId, true);
    }

    handleCloseComesFromAnswers(questionId) {
        this.setComesFromAnswersBoxState(questionId, false);
    }

    setComesFromAnswersBoxState(questionId, state) {
        let questionsCopy = [...this.state.questions];
        for(let question of questionsCopy) {
            if(question.id === questionId) {
                question.comesFromAnswersOpen = state;
            }
        }
        this.setState({
            questions: questionsCopy
        })
    }

    handleChangeNewQuestion(event) {
        this.setState({
            newQuestion: event.target.value
        })
    }

    render() {
        return(
            <div className="choose-next-question">
                <div className="answer">{this.props.answerText}</div>
                <span className="close icon action fa-window-close" title="Abbrechen" onClick={this.props.onCancel} />
                <div className="questions-to-choose">
                    <form onSubmit={this.handleAddNewQuestion}>
                        <input type="text" name="newQuestion" value={this.state.newQuestion} onChange={this.handleChangeNewQuestion} placeholder="Neue Frage"/>
                    </form>
                    
                    { this.state.questions.map(question =>
                    <div className="question-box" key={question.id}>
                        <div className="select">
                            <div className="question-to-choose-box">
                                <div className="open-close-box">
                                    { question.comesFromAnswersOpen && question.comesFromAnswers.length > 0 &&
                                        <span className="open-close-action icon action solid fa-caret-up"
                                            onClick={this.handleCloseComesFromAnswers.bind(this, question.id)}></span> }
                                    { !question.comesFromAnswersOpen && question.comesFromAnswers.length > 0 &&
                                        <span className="open-close-action icon action solid fa-caret-down"
                                            onClick={this.handleOpenComesFromAnswers.bind(this, question.id)}></span> }
                                </div>
                                <div className={"question-to-choose" + (this.props.currentQuestionId === question.id ? " active" : "")}
                                    onClick={() => this.props.onSelect(question.id)}>
                                        {question.question} <i>[{question.comesFromAnswers.length}]</i>
                                        { question.comesFromAnswersOpen &&
                                            <div className="comes-from-answers-box">{ question.comesFromAnswers.map(comesFrom =>
                                                <div className="comes-from-answer">{comesFrom.comesFromQuestion} - {comesFrom.comesFromAnswer}</div>
                                                )}
                                            </div> }
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    <div className="footer">
                        <span
                            className="remove-next-question action icon fa-trash-alt"
                            onClick={() => this.props.onSelect(null)}>Nächste Frage entfernen</span>
                    </div>
                </div>
            </div>
        )}
}
SelectOrAddQuestion.contextType = UserContext;