import {getURL} from '../config/constants';

export function loadConfigAPI(success, error) {
    fetch(getURL('config/getConfig.php'), {
            headers: { "Cache-Control": "no-cache" }
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            } else {
                error(response.status)
                throw Error();
            }
        })
        .then((data) => { success(data); })
        .catch(() => {});
}
