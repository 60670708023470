import {getURL} from '../config/constants';

export function loginAPI(loginname, password, success, forbidden, error) {
    fetch(getURL('/user/login.php'), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                loginname: loginname,
                password: password
            })
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            } else if(response.status === 403) {
                forbidden();
                throw Error();
            } else {
                error();
                throw Error();
            }
        })
        .then((data) => {
            success(data);
        })
        .catch(() => {});
}

export function logoutAPI(token, success, forbidden, error) {
    fetch(getURL('user/logout.php'),
        {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else if(response.status === 403) {
                forbidden();
            } else {
                error();
            }
        })
        .catch(() => {});
}

export function refreshSessionAPI(token, success, forbidden, error) {
    fetch(getURL('user/refreshSession.php'),
        {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token: token })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else if(response.status === 403) {
                forbidden();
            } else {
                error();
            }
        })
        .catch(() => {});
}

export function changePasswordAPI(oldPassword, newPassword, token, success, error) {
    fetch(getURL('user/changePassword.php'), {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                oldPassword: oldPassword,
                newPassword: newPassword,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else {
                return error(response.status);
            }
        })
        .catch(() => {});
}
