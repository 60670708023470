import React from 'react';
import ArticleList from './articleList';

/**
 * Component Sidebar
 * 
 * Properties:
 * onClickArticle: Event handler for clicking on an article
 */
export default class Sidebar extends React.Component {
    render() {
        return (
            <section id="sidebar">

                <section id="intro">
                    <header>
                        <h2>Frag Dich</h2>
                        <p>Fragen, die zum Nachdenken anregen möchten und auf Antworten warten</p>
                    </header>
                </section>

                <ArticleList
                    articles={this.props.articles}
                    onClick={this.props.onClickArticle}
                    onLogout={this.props.onLogout}
                    onAddArticle={this.props.onAddArticle} />
                
                <section className="blurb">
                    <h2>Über mich</h2>
                    <p>Wer ich bin, spielt im Grunde keine Rolle. Welche Gedanken mich ausmachen, kannst Du erfahren, wenn Du mit mir zusammen darüber denkst.<br/>
                       Aber mach Dir dabei bitte Deine eigenen Gedanken. </p>
                </section>

                <section id="footer">
                    <ul className="icons">
                        <li><a href="mailto:info@frag-dich.de" className="icon solid fa-envelope"><span className="label">Email</span></a></li>
                    </ul>
                    <p className="copyright">Tfod Aquila. Design: <a href="http://html5up.net">HTML5 UP</a>. Images: <a href="http://pixabay.com">bixabay</a>.</p>
                </section>

            </section>
        );
    }
}
