import React from 'react';

const Modal = ({ show, children, className }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';
    const classNames = 'modal-main '+className;
    return (
      <div className={showHideClassName}>
        <section className={classNames}>
          {children}
        </section>
      </div>
    );
  };

  export default Modal;