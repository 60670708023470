import React from 'react';

/**
 * Component Message
 * 
 * Properties:
 * message: The message to display
 * timeout: The time the message should be displayd
 * 
 * onTimeout: Event handler for notifying that the message no longer should be displayed
 */
export default class Message extends React.Component {

    componentDidMount() {
        this.timerID = setInterval(() => this.messageTimeout(), this.props.timeout);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }
    
    messageTimeout() {
        clearInterval(this.timerID);
        this.props.onTimeout();
    }

    render() {
        return(
            <div className="message">{this.props.message}</div>
        );
    }
}