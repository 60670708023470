import React from 'react';
import JoditEditor from "jodit-react";
import 'jodit/build/jodit.min.css';

/**
 * Component EditText
 * 
 * Properties:
 * value:       The starting value for the input field
 * placeholder: The placeholder for the empty field
 * onOkay:      The event handler for changing the value
 * onCancel:    The event handler for canceling the edit 
 */
export default class EditText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.value
        };
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSaveText = this.handleSaveText.bind(this);
        this.handleCancelEditText = this.handleCancelEditText.bind(this);
        this.escFunction = this.escFunction.bind(this);

        this.textInput = React.createRef();
    }

    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
        this.textInput.current.focus();
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleChangeText(event) {
        this.setState({
            text: event.target.value
        })
    }
    handleJoditChangeText = (newText) => {
        this.setState({ text: newText});
    }

    handleSaveText(event) {
        this.props.onOkay(this.state.text);
        event.preventDefault();
    }

    handleCancelEditText(event) {
        this.props.onCancel();
    }

    escFunction(event){
        if(event.keyCode === 27) {
            this.props.onCancel();
        }
    }

    /**
     * @property Jodit jodit instance of native Jodit
     */
	jodit;
    setRef = jodit => this.jodit = jodit;
    
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.type !== "textarea";
    }

    render() {
        return (
            <>
            {this.props.type === "textarea" &&
                <div className="edit-html-text">
                <JoditEditor
                    ref={this.textInput}
                    editorRef={this.setRef}
                    value={this.state.text}
                    config={{ readonly: false }}
                    tabIndex={1}
                    onChange={this.handleJoditChangeText}
                    />
                    <div className="edit-actions">
                        <span className="icon action fa-thumbs-up" onClick={this.handleSaveText}>Übernehmen</span>
                        <span className="icon action fa-thumbs-down" onClick={this.props.onCancel}>Abbrechen</span>
                    </div>
                </div>}

            {this.props.type !== "textarea" &&
            <form onSubmit={this.handleSaveText}>
                    <input className="text" ref={this.textInput} type="text" value={this.state.text} onChange={this.handleChangeText} placeholder={this.props.placeholder} />
            </form> }
            </>
        );
    }
}
