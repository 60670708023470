import React from 'react';
import EditText from './editText';
import SelectOrAddQuestion from './selectOrAddQuestion';
import Answer from './answer';

/**
 * Component AnswerList
 * 
 * Properties:
 * - article  The article object
 * - answers  The answers to display
 * - actionable Sets, if the component is currently actionable
 * - extended Should this component show its extended functionality
 * - onClick  The event handler for clicking an answer
 * - onChange The event handler for changing the text of an answer
 * - onChangeNextQuestion: The event handler for changing the next question for an answer (Param: answerId, questionId)
 * - onAddNewQuestion:     The event handler for adding a new next question for an answer (Param: answerId, newQuestion)
 * - onAddNewAnswer:       The event handler for adding a new answer to a question (Param: newAnswer)
 * - onDeleteAnswer:       The event handler for deleting an answer (Param: answerId)
 * - onMarkEditing:        The event handler for marking the component to be in edit mode
 * - onEndEditing:         The event handler for marking the component to be not in edit mode
 * - onLogout:             The event handler for notifying about a session logout
*/
export default class AnswerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            editAnswerId: -1,
            answer: null,
            addAnswer: false
        };
        this.handleStartSelectNextQuestion = this.handleStartSelectNextQuestion.bind(this);
        this.handleSelectNextQuestion = this.handleSelectNextQuestion.bind(this);
        this.handleAddNextQuestion = this.handleAddNextQuestion.bind(this);
        this.handleCancelSelectNextQuestion = this.handleCancelSelectNextQuestion.bind(this);

        this.handleStartEditAnswer = this.handleStartEditAnswer.bind(this);
        this.handleEditAnswer = this.handleEditAnswer.bind(this);
        this.handleCancelEditAnswer = this.handleCancelEditAnswer.bind(this);

        this.handleStartAddAnswer = this.handleStartAddAnswer.bind(this);
        this.handleAddAnswer = this.handleAddAnswer.bind(this);
        this.handleCancelAddAnswer = this.handleCancelAddAnswer.bind(this);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleStartSelectNextQuestion(answer) {
        this.setState({
            edit: true,
            editAnswerId: -1,
            answer: answer
        });
    }
    handleSelectNextQuestion(questionId) {
        let answerId = this.state.answer.id;
        this.setState({
            edit: false,
            answer: null
        });
        this.props.onChangeNextQuestion(answerId, questionId);
    }
    handleAddNextQuestion(newQuestion) {
        let answerId = this.state.answer.id;
        this.setState({
            edit: false,
            answer: null
        });
        this.props.onAddNewQuestion(answerId, newQuestion);
    }
    handleCancelSelectNextQuestion() {
        this.setState({
            edit: false,
            answer: null
        });
    }

    handleStartEditAnswer(answerId) {
        this.setState({
            editAnswerId: answerId
        });
        this.props.onMarkEditing();
    }
    handleEditAnswer(id, value) {
        this.setState({
            editAnswerId: -1
        })
        this.props.onChange(id, value);
        this.props.onEndEditing();
    }
    handleCancelEditAnswer() {
        this.setState({
            editAnswerId: -1
        });
        this.props.onEndEditing();
    }


    handleStartAddAnswer() {
        this.setState({
            addAnswer: true
        })
        this.props.onMarkEditing();
    }
    handleAddAnswer(newAnswer) {
        this.setState({
            addAnswer: false
        });
        this.props.onAddNewAnswer(newAnswer);
        this.props.onEndEditing();
    }
    handleCancelAddAnswer() {
        this.setState({
            addAnswer: false
        })
        this.props.onEndEditing();
    }

    handleLogout() {
        this.setState({
            edit: false
        })
        this.props.onEndEditing();
        this.props.onLogout();
    }

    render() {
        return (
            <>
                {this.state.edit === true &&
                    <SelectOrAddQuestion
                        articleId={this.props.article.id}
                        answerText={this.state.answer.answer}
                        currentQuestionId={this.state.answer.leads_to_question_id}
                        onCancel={this.handleCancelSelectNextQuestion}
                        onSelect={this.handleSelectNextQuestion}
                        onAdd={this.handleAddNextQuestion}
                        onLogout={this.handleLogout} /> }
                {this.state.edit !== true &&
                    <div className="answers">{
                        this.props.answers.map((answer, index) => 
                        {
                            const editAnswer =
                            <EditText
                                placeholder=""
                                value={answer.answer}
                                onOkay={(answerSentence) => this.handleEditAnswer(answer.id, answerSentence)}
                                onCancel={this.handleCancelEditAnswer} />
                            return (
                                <Answer
                                    key={answer.id}
                                    answer={answer}
                                    edit={this.state.editAnswerId === answer.id}
                                    editComponent={editAnswer}
                                    upable={index !== 0}
                                    downable={index !== this.props.answers.length-1}
                                    actionable={this.props.actionable}
                                    extended={this.props.extended}
                                    onClick={this.props.onClick}
                                    onSelectQuestion={this.handleStartSelectNextQuestion}
                                    onDelete={this.props.onDeleteAnswer}
                                    onEdit={this.handleStartEditAnswer}
                                    onUp={this.props.onUpAnswer}
                                    onDown={this.props.onDownAnswer} />);
                        })}
                    { this.state.addAnswer === false && this.props.extended && this.props.actionable && this.state.editAnswerId === -1 &&
                        <div className="answer">
                            <div className="answer-text add" onClick={this.handleStartAddAnswer} title="Neue Anwort hinzufügen">
                                <span className="icon fa-plus-square" />
                            </div>
                        </div>}
                    { this.state.addAnswer &&
                        <div className="answer">
                            <div className="answer-text add" onClick={this.handleStartAddAnswer} title="Neue Anwort hinzufügen">
                                <EditText
                                    value=""
                                    placeholder="Neue Antwort"
                                    onOkay={this.handleAddAnswer}
                                    onCancel={this.handleCancelAddAnswer} />
                            </div>
                        </div>}
                    </div>}
            </>
        );
    }

}