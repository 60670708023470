import React from 'react';
import ConfirmPopup from './confirmPopup';

/**
 * Component Answer
 * 
 * Properties:
 * - answer:     The answer to display
 * - actionable: Should this component display its actions
 * - extended:   Should this component dipslay its extended functionality
 * 
 * - onClick:          The event handler for clicking on an answer
 * - onSelectQuestion: The event handler for selecting a new question for this answer
 * - onDelete:         The event handler for deleting this answer
 * - onEdit:           The event handler for editing this answer
 * - onUp:             The event handler for setting the position of the answer one time up
 * - onDown:           The event handler for setting the position of the answer one time down
 */

export default class Answer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteAnswerPopup: false
        }
    }

    handleClick(event, target) {
        target();
        event.stopPropagation();
    }

    render() {
        const actionable = this.props.extended && this.props.actionable;
        const extended = this.props.extended;
        const clickable = this.props.actionable;

        return(
            <>
                <ConfirmPopup
                    show={this.state.showDeleteAnswerPopup}
                    title="Antwort löschen"
                    text="Bist Du Dir sicher, dass Du diese Antwort wirklich löschen willst?"
                    okayText="Ja, ich bin mir sicher!"
                    cancelText="Nein"
                    onClose={() => this.setState({showDeleteAnswerPopup: false})}
                    onOkay={() => this.props.onDelete(this.props.answer.id)}/>

                <div className="answer">
                    { /* ----- A N S W E R   T E X T ------------------------*/ }
                    { !this.props.edit &&
                        <div className={"answer-text" + (!actionable ? " view" : " edit")}
                            onClick={() => {if(clickable) {this.props.onClick(this.props.answer.id)}}} >
                            <span className="text">{this.props.answer.answer}</span>
                        </div> }
                    { /* ----- Edit ------------------------*/ }
                    { this.props.edit &&
                        <div className="answer-text edit">{this.props.editComponent }</div> }
                    {extended &&
                        <div className="answer-admin">
                            { /* ----- A C T I O N S ------------------------*/ }
                            {actionable &&
                                <div className="answer-actions">
                                { /* ----- Up Icon ------------------------*/ }
                                {this.props.upable &&
                                    <span
                                        className="up action icon solid fa-caret-left"
                                        onClick={(event) => this.handleClick(event, () => this.props.onUp(this.props.answer.id))}
                                        title="Antwort nach hinten"/> }
                                    { /* ----- Edit Icon ------------------------*/ }
                                    <span className="icon action solid fa-pen"
                                        onClick={(event) => this.handleClick(event, () => this.props.onEdit(this.props.answer.id))}
                                        title="Antwort bearbeiten"/>
                                    { /* ----- Delete Icon ------------------------*/ }
                                    <span className={"icon action solid fa-trash"}
                                        onClick={(event) => this.handleClick(event, () => this.setState({showDeleteAnswerPopup: true}))}
                                        title="Antwort löschen"/>
                                    { /* ----- Down Icon ------------------------*/ }
                                    {this.props.downable &&
                                        <span
                                            className="down action icon solid fa-caret-right"
                                            onClick={(event) => this.handleClick(event, () => this.props.onDown(this.props.answer.id))}
                                            title="Antwort nach vorne"/> }
                                </div>
                            }
                            { /* ----- N E X T   Q U E S T I O N ------------------------*/ }
                            {extended &&
                                <div
                                    className={"next-question-text" + (this.props.answer.leads_to_question_id === null ? " empty" : "")}
                                    onClick={() => { if(this.props.actionable) {this.props.onSelectQuestion(this.props.answer)}}}>
                                    { this.props.answer.leads_to_question_id !== null && this.props.answer.leads_to_question}
                                </div>}
                        </div>}
                </div>
            </>
        );
    }
}
