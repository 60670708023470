import React from 'react';
import HistoryElement from './historyElement';
import AnswerList from './answerList';
import EditText from './editText';
import QuestionAnswerTree from './questionAnswerTree';
import {UserContext, Role} from '../context/user-context';
import {articleimages, avatarimages} from '../config/constants';
import 'jodit';
import JoditEditor from "jodit-react";
import 'jodit/build/jodit.min.css';
import ArticleAdminHeader from './articleAdminHeader';
import ConfirmPopup from './confirmPopup';
import ChooseImage from './chooseImage';
import SelectOrAddQuestion from './selectOrAddQuestion';
import {getQuestionAPI} from '../api/publicApi';
import {
    editArticleImageAPI,
    editArticleStateAPI,
    setMainArticleAPI,
    deleteArticleAPI,

    addNextQuestionAPI,
    editQuestionAPI,
    editQuestionDescriptionAPI,
    deleteQuestionAPI,

    addAnswerAPI,
    deleteAnswerAPI,
    upAnswerAPI,
    downAnswerAPI,
    editAnswerAPI,
    editNextQuestionAPI,
    selectFirstQuestionAPI,
    removeFirstQuestionAPI
    } from '../api/adminApi';

import {editArticleTitleAPI, editArticleSubtitleAPI, editArticleTextAPI} from '../api/ArticleAPI';

/**
 * # Component Article
 * 
 * Properties:
 * - article:         The article to show
 * - mainArticleId:   The main article from config
 * - onChangeArticle: Event handler for notifiying about article change
 * - onDeleteArticle: Event handler for notifiying about article deletion
 * - onChangeConfig:  Event handler for changing the config
 * - onLogout:        Event handler for logging out the user
 * - onShowMessage:   Event handler to show a message in the header
 */
export default class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [],
            currentQuestion: null,
            newFirstQuestion: '',
            newTextContent: this.props.article.text,
            value: this.props.article.text,
            editElement: null,
            showDeleteArticlePopup: false,
            showChooseImagePopup: false,
            showDeleteQuestionPopup: false,
            treeReloadTrigger: false,
            editArticle: false
        };
    }

    element = {
        summary: 1,
        description: 2,
        text: 3,
        selectFirstQuestion: 4,
        question: 5,
        questionDescription: 6,
        answerList: 7
    }

    componentDidMount() {
        if(this.props.article.first_question_id !== null) {
            this.setCurrentFirstQuestion(this.props.article.first_question_id);
        } else {
            this.setState({
                history: [],
                currentQuestion: null
            })
        }
    }

    setCurrentFirstQuestion(questionId) {
        getQuestionAPI(questionId,
            (question) => {
                this.setState({
                    history: [],
                    currentQuestion: question
                })
            },
            () => {
                this.setState({
                    history: [],
                    currentQuestion: null
                })
                this.props.onShowMessage("Es ist ein Fehler aufgetreten!");
            }
        );
    }

    /*
+--------------------------------------------------------------------------------------------+
| A R T I C L E                                                                              |
+--------------------------------------------------------------------------------------------+
    */

    handleSetTextContent = (newContent) => this.setState({ newTextContent: newContent });

    handleEditArticleSummary = (title) => {
        editArticleTitleAPI(this.props.article.id, title, this.context.user.token,
            () => {
                this.props.onChangeArticle(Object.assign(this.props.article, { summary: title}))
                this.handleCancelEdit();
            },
            () => this.props.onLogout()
        );
    }

    handleChangeArticleDescription = (subtitle) => {
        editArticleSubtitleAPI(this.props.article.id, subtitle, this.context.user.token,
            () => {
                this.props.onChangeArticle(Object.assign(this.props.article, { description: subtitle}))
                this.handleCancelEdit();
            },
            () => this.props.onLogout()
        );
    }

    handleChangeArticleImage = (id) => {
        editArticleImageAPI(this.props.article.id, id, this.context.user.token,
            (data) => {
                this.props.onChangeArticle(Object.assign(this.props.article, { imageSrc: data.imageSrc}))
                this.setState({showChooseImagePopup: false})
            },
            () => this.props.onLogout());
    }

    handleEditArticleText = () => {
        editArticleTextAPI(this.props.article.id, this.state.newTextContent, this.context.user.token,
            () => {
                this.props.onChangeArticle(Object.assign(this.props.article, { text: this.state.newTextContent}))
                this.handleCancelEdit();
            },
            () => this.props.onLogout()
        );
    }

    handleEditArticleState = (newArticleStatus) => {
        editArticleStateAPI(this.props.article.id, newArticleStatus, this.context.user.token,
            () => this.props.onChangeArticle(Object.assign(this.props.article, { status: newArticleStatus})),
            () => this.props.onLogout()
        );
    }

    handleSetMainArticle = (id) => {
        setMainArticleAPI(id, this.context.user.token,
            () => this.props.onChangeConfig({firstArticleId: id}),
            () => this.props.onLogout());
    }

    handleDeleteArticle = () => {
        this.setState({showDeleteArticlePopup: false})
        deleteArticleAPI(this.props.article.id, this.context.user.token,
            () => this.props.onDeleteArticle(this.props.article.id),
            () => this.props.onLogout());
    }

    /*
+--------------------------------------------------------------------------------------------+
| Q U E S T I O N                                                                            |
+--------------------------------------------------------------------------------------------+
    */

   handleSetCurrentQuestion = (questionId) => {
        this.setCurrentFirstQuestion(questionId);
    }

    handleAddFirstQuestion = (newQuestion) => {
        addNextQuestionAPI(this.props.article.id, -1, newQuestion, this.context.user.token,
            (question) => {
                this.setState({
                    editElement: null,
                    currentQuestion: question,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                })
                this.props.onChangeArticle(Object.assign(this.props.article, { 
                    first_question_id: question.id
                }))
            },
            () => this.props.onLogout()
        );
    }

    handleAddQuestion = (answerId, newQuestion) => {
        addNextQuestionAPI(this.props.article.id, answerId, newQuestion, this.context.user.token,
            (question) => {
                let currentQuestion = Object.assign(this.state.currentQuestion);
                for(let answer of currentQuestion.answers) {
                    if(answer.id === answerId) {
                        answer.leads_to_question_id = question.id;
                        answer.leads_to_question = question.question;
                    }
                }
                this.setState({
                    currentQuestion: currentQuestion,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                });
            },
            () => this.props.onLogout()
        );
    }

    handleSelectAnswer = (answerId) => {
        let answer;
        for(const item of this.state.currentQuestion.answers) {
            if(item.id === answerId) {
                answer = item;
                break;
            }
        }
        if(answer.leads_to_question_id !== null) {
            getQuestionAPI(answer.leads_to_question_id,
                (data) => {
                    this.setState({
                        history: [...this.state.history].concat(Object.assign(this.state.currentQuestion, {selectedAnswer: answerId})),
                        currentQuestion: data
                    })
                }
            );
        }
    }

    handleGoToPreviousQuestion = () => {
        if(this.state.history.length > 0) {
            let history = [...this.state.history];
            let currentQuestion = history.pop();
            this.setState({
                history: history,
                currentQuestion: currentQuestion
            })
        }
    }

    handleEditQuestion = (value) => {
        editQuestionAPI(this.state.currentQuestion.id, value, this.context.user.token,
            () => {
                this.setState({
                    currentQuestion: Object.assign(this.state.currentQuestion, { question: value}),
                    editElement: null,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                })
            },
            () => this.props.onLogout()
        );
    }

    handleEditQuestionDescription = (value) => {
        editQuestionDescriptionAPI(this.state.currentQuestion.id, value, this.context.user.token,
            () => {
                this.setState({
                    currentQuestion: Object.assign(this.state.currentQuestion, { description: value}),
                    editElement: null
                })
            },
            () => this.props.onLogout()
        );
    }

    handleDeleteQuestion = () => {
        deleteQuestionAPI(this.state.currentQuestion.id, this.context.user.token,
            () => {
                this.setState({
                    showDeleteQuestionPopup: false,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                });
                if(this.props.article.first_question_id !== null) {
                    this.setCurrentFirstQuestion(this.props.article.first_question_id);
                } else {
                    this.setState({
                        currentQuestion: null,
                        history: []
                    })
                }
            },
            (status, message) => {console.log(this.state.currentQuestion.id, ": ", status, " - ", message)}
            );
    }

    /*
+--------------------------------------------------------------------------------------------+
| A N S W E R                                                                                |
+--------------------------------------------------------------------------------------------+
    */

    handleAddAnswer = (value) => {
        addAnswerAPI(this.state.currentQuestion.id, value, this.context.user.token,
            (data) => {
                let currentQuestion = Object.assign(this.state.currentQuestion);
                currentQuestion.answers = currentQuestion.answers.concat(data);
                this.setState({
                    currentQuestion: currentQuestion,
                    editElement: null,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                });
            },
            () => this.props.onLogout()
        );
    }

    handleDeleteAnswer = (id) => {
        deleteAnswerAPI(id, this.context.user.token,
            () => {
                let currentQuestion = Object.assign(this.state.currentQuestion);
                var i = currentQuestion.answers.length
                while (i--) {
                    if(currentQuestion.answers[i].id === id) {
                        currentQuestion.answers.splice(i, 1);
                    }
                }
                this.setState({
                    currentQuestion: currentQuestion,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                });
            },
            () => this.props.onLogout()
        );
    }

    handleUpAnswer = (id) => {
        upAnswerAPI(id, this.context.user.token,
            () => {
                let currentQuestion = Object.assign(this.state.currentQuestion);
                for(let i=0; i<currentQuestion.answers.length; i++) {
                    if(currentQuestion.answers[i].id === id) {
                        let dummy = currentQuestion.answers[i-1];
                        currentQuestion.answers[i-1] = currentQuestion.answers[i];
                        currentQuestion.answers[i] = dummy;
                        break;
                    }
                }
                this.setState({
                    currentQuestion: currentQuestion,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                })
            },
            () => this.props.onLogout());
    }
    handleDownAnswer = (id) => {
        downAnswerAPI(id, this.context.user.token,
            () => {
                let currentQuestion = Object.assign(this.state.currentQuestion);
                for(let i=0; i<currentQuestion.answers.length; i++) {
                    if(currentQuestion.answers[i].id === id) {
                        let dummy = currentQuestion.answers[i+1];
                        currentQuestion.answers[i+1] = currentQuestion.answers[i];
                        currentQuestion.answers[i] = dummy;
                        break;
                    }
                }
                this.setState({
                    currentQuestion: currentQuestion,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                })
            },
            () => this.props.onLogout());
    }

    handleEditAnswer = (id, value) => {
        editAnswerAPI(id, value, this.context.user.token,
            () => {
                let currentQuestion = Object.assign(this.state.currentQuestion);
                for(let answer of currentQuestion.answers) {
                    if(answer.id === id) {
                        answer.answer = value;
                    }
                }
                this.setState({
                    currentQuestion: currentQuestion,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                })
        },
            () => this.props.onLogout()
        );
    }

    handleEditFollowUpQuestion = (answerId, questionId) => {
        editNextQuestionAPI(answerId, questionId, this.context.user.token,
            (data) => {
                let currentQuestion = Object.assign(this.state.currentQuestion);
                for(let answer of currentQuestion.answers) {
                    if(answer.id === answerId) {
                        answer.leads_to_question_id = questionId;
                        answer.leads_to_question = data.question;
                    }
                }
                this.setState({
                    currentQuestion: currentQuestion,
                    treeReloadTrigger: !this.state.treeReloadTrigger
                });
            },
            () => this.props.onLogout()
        );
    }

    handleSelectFirstQuestion = (questionId) => {
        this.handleCancelEdit();
        if(questionId == null) {
            removeFirstQuestionAPI(this.props.article.id, this.context.user.token,
                () => {
                    this.setState({
                        editElement: null,
                        currentQuestion: null,
                        treeReloadTrigger: !this.state.treeReloadTrigger
                    })
                    this.props.onChangeArticle(Object.assign(this.props.article, { 
                        first_question_id: null
                    }))
                },
                () => this.props.onLogout()
            );
        } else {
            selectFirstQuestionAPI(this.props.article.id, questionId, this.context.user.token,
                (question) => {
                    this.setState({
                        editElement: null,
                        currentQuestion: question,
                        treeReloadTrigger: !this.state.treeReloadTrigger
                    })
                    this.props.onChangeArticle(Object.assign(this.props.article, { 
                        first_question_id: question.id
                    }))
                    },
                () => this.props.onLogout()
            );
        }
    }

    /*
+--------------------------------------------------------------------------------------------+
| M I S C                                                                                    |
+--------------------------------------------------------------------------------------------+
    */

   handleEdit = (element) => this.setState({ editElement: element });

   handleCancelEdit = () => this.setState({ editElement: null });

   formatISODate = (isoDate) => {
        const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
        let d = new Date(isoDate);
        return d.getDate() + ". " + months[d.getMonth()] + " " + d.getFullYear();
    }

    // Access control
    isInEdit(element) {
        return this.state.editElement === element;
    }

    /**
     * @property Jodit jodit instance of native Jodit
     */
	jodit;
    setRef = jodit => this.jodit = jodit;
    
    shouldComponentUpdate(nextProps, nextState) {
        return this.state.editElement !== this.element.text || nextState.editElement === null;
    }

    /*
+--------------------------------------------------------------------------------------------+
| R E N D E R                                                                                |
+--------------------------------------------------------------------------------------------+
    */
    
    render() {
        const config = {
            readonly: false // all options from https://xdsoft.net/jodit/doc/
        }

        const editable = this.context.isRole(Role.author) && this.state.editElement === null && this.state.editArticle;

        return (
            <div id="main">
                { this.context.isRole(Role.admin) &&
                    <ArticleAdminHeader
                        article={this.props.article}
                        edit={this.state.editArticle}
                        mainArticleId={this.props.mainArticleId}
                        onPublishUnpublish={this.handleEditArticleState}
                        onDeleteArticle={this.handleDeleteArticle}
                        onSetMainArticle={this.handleSetMainArticle}
                        onEditSummary={() => this.handleEdit(this.element.summary)}
                        onEditDescription={() => this.handleEdit(this.element.description)}
                        onEditText={() => this.handleEdit(this.element.text)}
                        onChooseImage={() => this.setState({ showChooseImagePopup: true})}
                        onEdit={(edit) => this.setState({ editArticle: edit })} />
                }
                <ChooseImage
                    show={this.state.showChooseImagePopup}
                    onClose={() => this.setState({showChooseImagePopup: false})}
                    onLogout={this.props.onLogout}
                    onChangeArticleImage={this.handleChangeArticleImage} />
                <ConfirmPopup
                    show={this.state.showDeleteArticlePopup}
                    title="Artikel löschen"
                    text="Bist Du Dir sicher, dass Du diesen Artikel wirklich löschen willst?"
                    okayText="Ja, ich bin mir sicher!"
                    cancelText="Nein"
                    onClose={() => this.setState({showDeleteArticlePopup: false})}
                    onOkay={this.handleDeleteArticle}/>
                <ConfirmPopup
                    show={this.state.showDeleteQuestionPopup}
                    title="Frage löschen"
                    text="Bist Du Dir sicher, dass Du diese Frage wirklich löschen willst? Hinweis: Es werden die Antworten der Frage auch gelöscht, nicht aber die weiter verlinkten Fragen!"
                    okayText="Ja, ich bin mir sicher!"
                    cancelText="Nein"
                    onClose={() => this.setState({showDeleteQuestionPopup: false})}
                    onOkay={this.handleDeleteQuestion}/>
                <article className="post">
                    { editable &&
                        <span className="delete-article-icon action icon solid fa-trash" title="Artikel löschen..." onClick={() => this.setState({showDeleteArticlePopup: true})} /> }
                    <header>
                        <div className="title">
{ /* ARTICLE SUMMARY  ------------------------------------------------------------------------------------- */}
                            <div className="summary">
                                {this.state.editElement !== this.element.summary &&
                                    <h2>{this.props.article.summary}
                                    </h2>}
                                {/* -------- Summary Edit Icon ------------------- */}
                                {editable &&
                                    <div className="action-box">
                                        <span
                                            className="icon action solid fa-pen"
                                            title="Überschrift ändern"
                                            onClick={()=>this.handleEdit(this.element.summary)}>Überschrift ändern</span>
                                    </div>}
                                {/* -------- Summary Edit ------------------- */}
                                {this.state.editElement === this.element.summary &&
                                    <EditText
                                        value={this.props.article.summary}
                                        placeholder="Überschrift"
                                        onOkay={this.handleEditArticleSummary}
                                        onCancel={this.handleCancelEdit} /> }
                            </div>
{ /* ARTICLE SUBTITLE  ------------------------------------------------------------------------------------- */}
                            <div className="subtitle">
                                <p>{this.state.editElement !== this.element.description && this.props.article.description}</p>
                                {/* -------- Subtitle Edit Icon ------------------- */}
                                {editable &&
                                    <div className="action-box">
                                        <span
                                            className="icon action solid fa-pen"
                                            title="Untertitel ändern"
                                            onClick={() => this.handleEdit(this.element.description)} >Untertitel ändern</span>
                                    </div>}
                                {/* -------- Subtitle Edit ------------------- */}
                                {this.state.editElement === this.element.description &&
                                    <EditText
                                        value={this.props.article.description}
                                        placeholder="Untertitel"
                                        onOkay={this.handleChangeArticleDescription}
                                        onCancel={this.handleCancelEdit} /> }
                            </div>
                        </div>
{ /* ARTICLE DATE AND AUTHOR  ------------------------------------------------------------------------------------- */}
                        <div className="meta">
                            <time className="published" dateTime={this.props.article.created}>{this.formatISODate(this.props.article.created)}</time>
                            <span className="author"><span className="name">{this.props.article.createdName}</span><img src={avatarimages('./avatar'+this.props.article.createdBy+'.jpg')} alt="" /></span>
                        </div>
                    </header>
{ /* ARTICLE IMAGE ------------------------------------------------------------------------------------- */}
                    <span className="image featured">
                        {/* -------- Image Edit Icon ------------------- */}
                        { editable &&
                            <div className="action-box">
                                <span
                                    className="action icon solid fa-pen"
                                    title="Bild ändern"
                                    onClick={() => this.setState({ showChooseImagePopup: true})}>Anderes Bild auswählen</span>
                            </div>}
                    {/* -------- Image Show ------------------- */}
                    { this.props.article.imageSrc === null && <img src={articleimages('./default.jpg')} alt="" /> }
                        { this.props.article.imageSrc !== null && <img src={articleimages('./'+this.props.article.imageSrc)} alt="" />}
                    </span>
{ /* ARTICLE TEXT ------------------------------------------------------------------------------------- */}
                    <div className="article-text">
                        {/* -------- Article Text ------------------- */}
                        { this.state.editElement !== this.element.text && this.props.article.text !== "" &&
                            <p dangerouslySetInnerHTML={{__html: this.props.article.text}} /> }
                        {/* -------- Edit Article Text Icon ------------------- */}
                        { editable &&
                            <div className="action-box">
                                <span
                                    className="icon action solid fa-pen-nib"
                                    onClick={() => this.handleEdit(this.element.text)}
                                    title="Text bearbeiten" >Artikeltext bearbeiten</span>
                            </div>}
                        {/* -------- Edit Article Text Editor ------------------- */}
                        { this.state.editElement === this.element.text &&
                            <JoditEditor
                                    editorRef={this.setRef}
                                    value={this.state.newTextContent}
                                    config={config}
                                    tabIndex={1}
                                    onChange={this.handleSetTextContent}
                                    />
                        }
                        {/* -------- Edit Article Text Editor Icons ------------------- */}
                        { this.state.editElement === this.element.text &&
                            <div className="edit-text-actions">
                                <span
                                    className="icon action fa-thumbs-up"
                                    onClick={this.handleEditArticleText}>Text übernehmen</span>
                                <span
                                    className="icon action fa-thumbs-down"
                                    onClick={this.handleCancelEdit}>Abbrechen</span>
                            </div>
                        }
                    </div>
{ /* FIRST QUESTION ------------------------------------------------------------------------------------- */}
                    
                    { editable &&
                        <div className="first-question-choose">
                            <div className="action-box">
                                <span
                                    className="action icon solid fa-pen"
                                    title="Erste Frage wählen"
                                    onClick={() => this.handleEdit(this.element.selectFirstQuestion)}>Erste Frage definieren</span>
                            </div>
                        </div> }
                    { this.isInEdit(this.element.selectFirstQuestion) &&
                        <SelectOrAddQuestion
                            articleId={this.props.article.id}
                            answerText=""
                            currentQuestionId={this.props.article.firstArticleId}
                            onCancel={this.handleCancelEdit}
                            onSelect={this.handleSelectFirstQuestion}
                            onAdd={this.handleAddFirstQuestion}
                            onLogout={this.props.onLogout} /> }
{ /* HISTORY ------------------------------------------------------------------------------------- */}
                    { this.state.history.map((question, index) => <HistoryElement key={"history"+index} question={question} />)}
{ /* CURRENT QUESTION ------------------------------------------------------------------------------------- */}
                    { this.state.currentQuestion !== null && this.renderDisplayQuestion() }
                    { this.state.currentQuestion !== null && this.renderAnswerList() }
                </article>
                { this.context.isRole(Role.author) && this.state.editArticle &&
                <QuestionAnswerTree
                    key = {this.state.treeReloadTrigger}
                    article  = {this.props.article}
                    onSetQuestion = {this.handleSetCurrentQuestion}
                    onLogout = {this.props.onLogout} />}
            </div>
        )
    }

    renderDisplayQuestion() {
        const editable = this.context.isRole(Role.author) && this.state.editElement === null && this.state.editArticle;

        return (
            <div className="current-question">
{ /* DESCRIPTION ------------------------ */}
                { /* Go to previous question --------------- */}
                {this.state.history.length > 0 &&
                    <span
                        className="previous-question-icon icon action fa-arrow-alt-circle-up"
                        onClick={this.handleGoToPreviousQuestion}
                        title="Zur vorherigen Frage zurückkehren"/> }
                { /* Show description --------------- */}
                { this.state.currentQuestion.description !== "" && this.state.editElement !== this.element.questionDescription &&
                    <div className="description"><p dangerouslySetInnerHTML={{__html: this.state.currentQuestion.description}} /></div> }
                { /* Edit description --------------- */}
                { this.state.editElement === this.element.questionDescription && 
                    <div className="edit-description">
                        <EditText
                            key={this.state.currentQuestion.id}
                            type="textarea"
                            placeholder="Text zur Frage"
                            value={this.state.currentQuestion.description}
                            onOkay={this.handleEditQuestionDescription}
                            onCancel= {this.handleCancelEdit} />
                    </div> }
{ /* QUESTION ------------------------ */}
                <div className="question-row">
                    { /* Show question --------------- */}
                    {this.state.editElement !== this.element.question && 
                        <div className="question">{this.state.currentQuestion.question}</div> }
                    {editable &&
                        <div className="action-box">
                            { /* Edit Question Icon --------------- */}
                            <span
                                className="edit-question-icon action icon solid fa-pen"
                                onClick={() => this.handleEdit(this.element.question)}
                                title="Frage bearbeiten"/>
                            { /* Edit Question Text Icon --------------- */}
                                <span
                                    className="action icon solid fa-pen-nib"
                                    onClick={() => this.handleEdit(this.element.questionDescription)}
                                    title="Text zur Frage bearbeiten" />
                            { /* Delete Question Icon --------------- */}
                            <span
                                className="delete-question-icon action icon solid fa-trash"
                                onClick={() => this.setState({showDeleteQuestionPopup: true })}
                                title="Frage löschen"/>
                        </div> }
                    {this.state.editElement === this.element.question && 
                        <EditText
                            key={this.state.currentQuestion.id}
                            placeholder=""
                            value={this.state.currentQuestion.question}
                            onOkay={this.handleEditQuestion}
                            onCancel= {this.handleCancelEdit} />
                    }
                </div>
            </div>
        );
    }

    renderAnswerList() {
        const actionable = this.state.editElement === null;
        return (
            <AnswerList
                actionable={actionable}
                extended={this.state.editArticle && this.context.isRole(Role.author)}
                article={this.props.article}
                answers={this.state.currentQuestion.answers === null ? [] : this.state.currentQuestion.answers}
                onClick={this.handleSelectAnswer}
                onChange={this.handleEditAnswer}
                onChangeNextQuestion={this.handleEditFollowUpQuestion}
                onAddNewQuestion={this.handleAddQuestion}
                onAddNewAnswer={this.handleAddAnswer}
                onDeleteAnswer={this.handleDeleteAnswer}
                onMarkEditing={() => this.handleEdit(this.element.answerList)}
                onEndEditing={this.handleCancelEdit}
                onLogout={this.props.onLogout}
                onUpAnswer={this.handleUpAnswer}
                onDownAnswer={this.handleDownAnswer} />);
    }

}
Article.contextType = UserContext;
