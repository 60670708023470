import {getURL} from '../config/constants';

export function loadArticlesAPI(success, error) {
    fetch(getURL('article/getAllPublicArticles.php'))
        .then((response) => {
            if(response.ok) {
                return response.json();
            } else {
                error(response.status);
                throw Error();
            }
        })
        .then((data) => {
            success(data);
        })
        .catch(() => {})
}

export function loadAllArticlesAPI(token, success, error) {
    fetch(getURL('article/getAllArticles.php'),
        {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token: token })
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            } else {
                error(response.status);
                throw Error();
            }
        })
        .then((data) => { success(data) })
        .catch(() => {});
}

export function addArticleAPI(token, success, error) {
    fetch(getURL('article/addArticle.php'),
        {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token: token })
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            } else {
                error(response.status);
                throw Error();
            }
        })
        .then((data) => { success(data); })
        .catch(() => {});
}

export function editArticleTitleAPI(id, value, token, success, error) {
    fetch(getURL('article/editArticleTitle.php'), {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                id: id,
                value: value,
                token: token
            })
        })
        .then((response) => {
            if(response.ok) {
                success();
            } else {
                error(response.status);
            }
        })
        .catch(() => {});
}

export function editArticleSubtitleAPI(id, value, token, success, error) {
    fetch(getURL('article/editArticleSubtitle.php'), {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                id: id,
                value: value,
                token: token
            })
        })
        .then((response) => {
            if(response.ok) {
                success();
            } else {
                error(response.status);
            }
        })
        .catch(() => {});
}

export function editArticleTextAPI(id, value, token, success, error) {
    fetch(getURL('article/editArticleText.php'), {
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                id: id,
                value: value,
                token: token
            })
        })
        .then((response) => {
            if(response.ok) {
                success();
            } else {
                error(response.status);
            }
        })
        .catch(() => {});
}
