import React from 'react';

class HistoryElement extends React.Component {

    getSelectedAnswer() {
        for(const answer of this.props.question.answers) {
            if(answer.id === this.props.question.selectedAnswer) {
                return answer.answer;
            }
        }
    }
    render() {
        return (
            <div className="question-history">
                { this.props.question.description !== null && this.props.question.description !== "" &&
                <p className="question-description" dangerouslySetInnerHTML={{__html: this.props.question.description}} /> }
                <div className="question">{ this.props.question.question }</div>
                <div className="history-answer">Ich: "{this.getSelectedAnswer()}"</div>
            </div>
        );
    }
}
export default HistoryElement;
