import React from 'react';
import {articleimages, avatarimages} from '../config/constants';
import {UserContext, Role} from '../context/user-context';

/**
 * Component ArticleList
 * 
 * Properties:
 * articles:   The list of articles loaded
 * onClick:    Event handler for clicking on an article
 * onLogout:   Event handler for notifying the session logout
 * onAddArticle: Event handler for adding a new article
 */
export default class ArticleList extends React.Component {

    render() {
        return (
            <section>
                <div className="mini-posts">
                { this.props.articles.map((article, index) => (
                <ArticleLink
                    key={article.id}
                    first={index === 0}
                    last={index === this.props.articles.length - 1}
                    article={article}
                    onClick={(article) => this.props.onClick(article)} />    
                )) }
                { this.context.isRole(Role.admin) &&
                    <div>
                        <span className="icon action fa-plus-square" onClick={this.props.onAddArticle}>Neuen Artikel hinzufügen</span>
                    </div>
                } 
                </div>
            </section>
        );
    }
}
ArticleList.contextType = UserContext;

/**
 * Component ArticleLink
 * 
 * Properties:
 * article: The article to display
 * onClick: Event handler for clicking on an article
 */
class ArticleLink extends React.Component {
    render() {
        return (
                <article key={this.props.article.id} className={"mini-post" + (this.props.article.status !== 1 ? " draft":"")}>
                    { this.context.isRole(Role.admin) &&
                    <div className="article-link-admin">
                        <div>{ !this.props.first && <span className="icon action fa-arrow-alt-circle-up"></span> }</div>
                        <div>{ !this.props.last && <span className="icon action fa-arrow-alt-circle-down"></span> }</div>
                    </div>
                    }
                    <header>
                        <h3><span className="clickable" onClick={() => this.props.onClick(this.props.article)}>{this.props.article.summary}</span></h3>
                        <time className="published" dateTime={this.props.article.created}>{this.props.article.created}</time>
                        <span className="author"><img src={avatarimages('./avatar'+this.props.article.createdBy+'.jpg')} alt="" /></span>
                    </header>
                    <span className="clickable image" onClick={() => this.props.onClick(this.props.article)}>
                        { this.props.article.imageSrc === null && <img src={articleimages('./default.jpg')} alt="" /> }
                        { this.props.article.imageSrc !== null && <img src={articleimages('./'+this.props.article.imageSrc)} alt="" />}
                    </span>
                </article>
        )
    }
}
ArticleLink.contextType = UserContext;
