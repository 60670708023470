import {domain} from '../config/constants';


export function getQuestionAPI(firstQuestionId, success) {
    const url = domain + 'server/api/question/getQuestion.php?id='+firstQuestionId;
    fetch(url, {
            headers: {
                "Cache-Control": "no-cache"
            }
        })
        .then((res) => {
            if(res.status === 200) {
                return res.json()
            } else {
                throw Error();
            }
        })
        .then((data) => {
            success(data);
        })
        .catch(console.log);
}

export function articleImagesAPI(success, logout) {
    fetch(domain + 'server/api/articleImages/getArticleImages.php',
        {
            method: "GET"
        })
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else if(response.status === 403) {
                logout();
                throw Error(response.status, response.statusText);
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .then((data) => {
            success(data);
        })
        .catch(console.log);
}
