import React from 'react';

import Modal from './modal';
import {loginAPI} from '../api/UserAPI';
import {UserContext} from '../context/user-context';

export default class LoginPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginname: '',
            password: '',
            errormessage: ''
        };
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
        this.setState({
            loginname: '',
            password: '',
            errormessage: ''
        });
    }

    handleLogin(event, contextState) {
        loginAPI(
            this.state.loginname,
            this.state.password,
            (data) => {
                contextState.loginUser({
                    id: data.id,
                    name: data.name,
                    token: data.token
                });
                this.setState({
                    errormessage: ''
                })
                this.props.onLogin();
                this.props.onClose();
            },
            () => { this.setState({ errormessage: "Benutzer oder Passwort falsch!" }); },
            () => { this.setState({ errormessage: "Es ist ein Fehler aufgetreten!" }); });
        event.preventDefault();
    }

    handleChangeUser(event) {
        this.setState({
            loginname: event.target.value,
        });
    }
    
    handleChangePassword(event) {
        this.setState({
            password: event.target.value
        });
    }

    render() {
        return (
            <UserContext.Consumer>
                { (contextState) => (
                    <Modal show={this.props.show} onClose={this.props.onClose} className="login">
                        <header>
                            <div>Log in</div>
                            <span className="icon action fa-window-close" onClick={this.props.onClose}></span>
                        </header>
                        <form onSubmit={(event) => this.handleLogin(event, contextState)}>
                            <div className="content form">
                                <div className="error-message">{this.state.errormessage}</div>
                                <input type="text" name="user" value={this.state.loginname} onChange={this.handleChangeUser} placeholder="Benutzer" />
                                <input type="password" name="pwd" value={this.state.password} onChange={this.handleChangePassword} placeholder="Passwort" />
                                <input type="submit" value="Anmelden" />
                            </div>
                        </form>
                    </Modal>
                )}
            </UserContext.Consumer>
        );
    }
}
