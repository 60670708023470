import React from 'react';
import Modal from './modal';

/**
 * ConfirmPopup
 * 
 * Properties:
 * - show:       Tells, if the popup should be shown or hidden
 * - title:      The title of the popup
 * - text:       The text to display in the popup
 * - okayText:   The okay text for the button
 * - cancelText: The cancel text for the button
 * - onClose:    Event handler to close the popup
 * - onOkay:     Event handler to confirm the action
 */
export default class ConfirmPopup extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} onClose={this.props.onClose} className="delete-article">
                <header>
                    <div>{this.props.title}</div>
                    <span className="icon action fa-window-close" onClick={this.props.onClose}></span>
                </header>
                <div className="content">
                    <p>{this.props.text}</p>
                    <input type="button" onClick={this.props.onOkay} value={this.props.okayText} />
                    <input type="button" onClick={this.props.onClose} value={this.props.cancelText}/>
                </div>
            </Modal>
        );
    }
}