import React from 'react';
import Modal from './modal';
import {UserContext, Role} from '../context/user-context';
import {avatarimages} from '../config/constants';
import {changePasswordAPI} from '../api/UserAPI';

const passwordRuleFailedMessage = 'Das neue Passwort muss mindestens 8 Zeichen haben!';
const oldPasswortWrongMessage = 'Das Passwort ist falsch!';
const passwordsDoNotMatchMessage = 'Die neuen Passwörter stimmen nicht überein!';
const unknownErrorMessage = 'Unbekannter Fehler!';
const oldPasswordMissingMessage = "Du musst Dein Passwort eingeben!";

export default class ProfilePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showChangePassword: false,
            errormessage: '',
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: ''
        }
    }

    handleShowChangePassword = () => {
        this.setState({
            showChangePassword: true,
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: ''
        })
    }

    handleLogout = () => {
        this.props.onClose();
        this.props.onLogout();
    }

    handleChangePassword = (event) => {
        if(this.state.oldPassword.length === 0) {
            this.setState({
                errormessage: oldPasswordMissingMessage
            })
            event.preventDefault();
            return;
        }
        // Compare new passwords
        if(this.state.newPassword !== this.state.newPasswordRepeat) {
            this.setState({
                errormessage: passwordsDoNotMatchMessage
            })
            event.preventDefault();
            return;
        }
        if(this.state.newPassword.length < 8) {
            this.setState({
                errormessage: passwordRuleFailedMessage
            })
            event.preventDefault();
            return;
        }
        changePasswordAPI(this.state.oldPassword, this.state.newPassword, this.context.user.token,
            () => { this.setState({ showChangePassword: false })},
            (status) => {
                let errorMessage = '';
                if(status === 401) {
                    errorMessage = oldPasswortWrongMessage;
                } else if(status === 400) {
                    errorMessage = passwordRuleFailedMessage;
                } else {
                    errorMessage = unknownErrorMessage;
                }
                this.setState({
                    errormessage: errorMessage
                })
            });
        this.setState({
            errormessage: ''
        })
        event.preventDefault();
    }

    handleChangeOldPassword = (event) => {
        this.setState({
            oldPassword: event.target.value
        });
    }

    handleChangeNewPassword = (event) => {
        this.setState({
            newPassword: event.target.value
        });
    }

    handleChangeNewPasswordRepeat = (event) => {
        this.setState({
            newPasswordRepeat: event.target.value
        });
    }

    handleClose = () => {
        this.setState({
            showChangePassword: false
        })
        this.props.onClose();
    }

    render() {
        return(
            <Modal show={this.props.show} onClose={this.handleClose} className="profile">
                <header>
                    <div>{this.state.showChangePassword ? "Passwort ändern" : "Profil" }</div>
                    <span className="icon action fa-window-close" onClick={this.handleClose}></span>
                </header>
                <div className="content">
                    { this.context.isRole(Role.author) &&
                    <div className="login-image"><img src={avatarimages('./avatar'+this.context.user.id+'.jpg')} alt="" /></div> }
                    <div className="login-name">{this.context.user.name}</div>
                    {this.state.showChangePassword &&
                        <form onSubmit={this.handleChangePassword} autoComplete="off">
                            <div className="form">
                                <div className="error-message">{this.state.errormessage}</div>
                                <input type="password" name="oldPassword" autoComplete="new-password" value={this.state.oldPassword} onChange={this.handleChangeOldPassword} placeholder="Bisheriges Passwort" />
                                <input type="password" name="newPassword" value={this.state.newPassword} onChange={this.handleChangeNewPassword} placeholder="Neues Passwort" />
                                <input type="password" name="newPasswordRepeat" value={this.state.newPasswordRepeat} onChange={this.handleChangeNewPasswordRepeat} placeholder="Neues Passwort" />
                                <input type="submit" value="Passwort ändern" />
                            </div>
                        </form> }
                    {!this.state.showChangePassword &&
                        <>
                            <button onClick={this.handleShowChangePassword}>Passwort ändern</button>
                            <button onClick={this.handleLogout}>Abmelden</button>
                        </> }
                </div>
            </Modal>
        );
    }
}
ProfilePopup.contextType = UserContext;
