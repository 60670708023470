import React from 'react';
import Modal from './modal';
import {UserContext} from '../context/user-context';
import {articleimages} from '../config/constants';
import {articleImagesAPI} from '../api/publicApi';

/**
 * Component ChooseImage
 * 
 * Properties:
 * - show:    Defines, if the popup has to be shown or not
 * - onClose: Event handler for closing the popup
 */
export default class ChooseImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articleImages: [],
            page: 0
        }
        this.pageImages = 6;
    }

    componentDidMount() {

        articleImagesAPI(
            (data) => {
                this.setState({
                    articleImages: data
                })
            },
            () => this.props.onLogout());
    }

    render() {
        let start = this.state.page * this.pageImages;
        let firstPage = this.state.page === 0;
        let lastPage = this.state.page === Math.ceil(this.state.articleImages.length/this.pageImages) - 1;
        let articleImages = this.state.articleImages.slice(start, start + this.pageImages);
        return(
            <Modal show={this.props.show} onClose={this.props.onClose} className="choose-image">
                <header>
                    <div>Bild auswählen</div>
                    <span className="icon action fa-window-close" onClick={this.props.onClose}></span>
                </header>
                <div className="article-images">
                { articleImages.map( articleImage => 
                    <div className="article-image" key={articleImage.id} onClick={() => this.props.onChangeArticleImage(articleImage.id)}>
                        <img src={articleimages("./"+articleImage.imageSrc)} alt={articleImage.name}/><div className="image-name">{articleImage.name}</div>
                    </div>
                )}
                </div>
                <footer>
                    {!firstPage && <span className="icon action fa-caret-square-left" onClick={() => {this.setState({page: this.state.page - 1})}}/>}
                    { firstPage && <span></span>}
                    {!lastPage && <span className="icon action fa-caret-square-right" onClick={() => {this.setState({page: this.state.page + 1})}}/>}
                    { lastPage && <span></span>}
                </footer>
            </Modal>
        )        
    }
}
ChooseImage.contextType = UserContext;
