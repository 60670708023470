import {domain} from '../config/constants';


export function getAllQuestionsFromArticleAPI(articleId, token, success, logout) {
    const url = domain + 'server/api/question/getAllQuestionsFromArticle.php';
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            articleId: articleId,
            token: token
        })
    }
    fetch(url, options)
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else if(response.status === 403) {
                logout();
                throw Error();
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .then((data) => {
            success(data);
        })
        .catch(console.log);
}

export function editQuestionAPI(id, value, token, success, logout) {
    const url = domain + 'server/api/question/editQuestionText.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                value: value,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                success();
            } else if(response.status === 403) {
                logout();
            } else {
                throw Error(response.statusText);
            }
        })
        .catch(console.log);
}

export function editAnswerAPI(id, value, token, success, logout) {
    const url = domain + 'server/api/answer/editAnswerText.php';
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id,
            value: value,
            token: token
        })
    }
    fetch(url, options)
        .then((response) => {
            if(response.status === 200) {
                success();
            } else if(response.status === 403) {
                logout();
            } else {
                throw Error(response.statusText);
            }
        })
        .catch(console.log);
}

export function editNextQuestionAPI(answerId, questionId, token, success, logout) {
    const url = domain + 'server/api/answer/editNextQuestion.php';
    if(questionId === null) {
        questionId = -1;
    }
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                answerId: answerId,
                nextQuestionId: questionId,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else if(response.status === 403) {
                logout();
                throw Error();
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .then((data) => {
            success(data);
        })
        .catch(console.log);
}

export function addNextQuestionAPI(articleId, answerId, newQuestion, token, success, error) {
    const url = domain + 'server/api/question/addQuestion.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                articleId: articleId,
                answerId: answerId,
                newQuestion: newQuestion,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else {
                error(response.status, response.statusText);
                throw Error();
            }
        })
        .then((data) => { success(data) })
        .catch(console.log);
}

export function addAnswerAPI(questionId, answerSentence, token, success, logout) {
    const url = domain + 'server/api/answer/addAnswer.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                questionId: questionId,
                newAnswer: answerSentence,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else if(response.status === 403) {
                logout();
                throw Error();
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .then((data) => { success(data) })
        .catch(console.log);
}

export function deleteAnswerAPI(id, token, success, logout) {
    const url = domain + 'server/api/answer/deleteAnswer.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                answerId: id,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                success();
            } else if(response.status === 403) {
                logout();
            } else {
                throw Error(response.statusText);
            }
        })
        .catch(console.log);
}

export function editArticleStateAPI(id, status, token, success, logout) {
    const url = domain + 'server/api/article/editArticleStatus.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                status: status,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                success();
            } else if(response.status === 403) {
                logout();
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .catch(console.log);
}

export function editQuestionDescriptionAPI(id, value, token, success, logout) {
    fetch(domain + 'server/api/question/editQuestionDescription.php',
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                value: value,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else if(response.status === 403) {
                logout();
                throw Error(response.status, response.statusText);
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .catch(console.log);
}

export function deleteArticleAPI(id, token, success, logout) {
    fetch(domain + 'server/api/article/deleteArticle.php',
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else if(response.status === 403) {
                logout();
                throw Error(response.status, response.statusText);
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .catch(console.log);
}

export function setMainArticleAPI(id, token, success, logout) {
    fetch(domain + 'server/api/config/setMainArticle.php',
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else if(response.status === 403) {
                logout();
                throw Error(response.status, response.statusText);
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .catch(console.log);
}

export function upAnswerAPI(id, token, success, logout) {
    fetch(domain + 'server/api/answer/moveAnswerUp.php',
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else if(response.status === 403) {
                logout();
                throw Error(response.status, response.statusText);
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .catch(console.log);
}

export function downAnswerAPI(id, token, success, logout) {
    fetch(domain + 'server/api/answer/moveAnswerDown.php',
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else if(response.status === 403) {
                logout();
                throw Error(response.status, response.statusText);
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .catch(console.log);
}

export function editArticleImageAPI(articleId, imageId, token, success, logout) {
    const url = domain + 'server/api/article/editArticleImage.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                articleId: articleId,
                imageId: imageId,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else if(response.status === 403) {
                logout();
                throw Error();
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .then((data) => { success(data) })
        .catch(console.log);
}

export function getAllQuestionsWithAnswersFromArticleAPI(articleId, token, success, logout) {
    const url = domain + 'server/api/question/getQuestionsForTree.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: articleId,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else if(response.status === 403) {
                logout();
                throw Error();
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .then((data) => { success(data) })
        .catch(console.log);
}
export function selectFirstQuestionAPI(articleId, questionId, token, success, logout) {
    if(questionId === null) {
        questionId = -1;
    }
    const url = domain + 'server/api/article/selectFirstQuestion.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                articleId: articleId,
                questionId: questionId,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return response.json();
            } else if(response.status === 403) {
                logout();
                throw Error();
            } else {
                throw Error(response.status, response.statusText);
            }
        })
        .then((data) => { success(data) })
        .catch(console.log);
}
export function removeFirstQuestionAPI(articleId, token, success, error) {
    const url = domain + 'server/api/article/removeFirstQuestion.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                articleId: articleId,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            } else {
                return error(response.status, response.statusText);
            }
        })
        .catch(console.log);
}

export function deleteQuestionAPI(questionId, token, success, error) {
    const url = domain + 'server/api/question/deleteQuestion.php';
    fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: questionId,
                token: token
            })
        })
        .then((response) => {
            if(response.status === 200) {
                return success();
            }
            error(response.status, response.statusText);
        })
        .catch(console.log);
}
