import React from 'react';

export const emptyUser = {
    id: -1,
    name: '',
    token: ''
}

export const Role = {
    user: 1,
    author: 2,
    admin: 3
}

export const UserContext = React.createContext(emptyUser);