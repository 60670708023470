import React from 'react';
import ReactDOM from 'react-dom';

import {UserContext, Role, emptyUser} from './context/user-context';
import Sidebar from './components/sidebar';
import Header from './components/header';
import Article from './components/article';
import './assets/css/main.css';

import {loadConfigAPI} from './api/ConfigAPI';
import {logoutAPI, refreshSessionAPI} from './api/UserAPI';
import {loadArticlesAPI, loadAllArticlesAPI, addArticleAPI} from './api/ArticleAPI';
    
class App extends React.Component {
    constructor(props) {
        super(props);
        this.login = (user) => {
            this.setState({
              user: user
            });
        };
        this.logout = () => {
            this.setState({
              user: emptyUser
            });
        };
        this.isRole = (role) => {
            if(role === Role.user) {
                return this.state.user.id === emptyUser.id;
            } else {
                return this.state.user.id !== emptyUser.id;
            }
        }
      
        this.state = {
            user: emptyUser,
            loginUser: this.login,
            logoutUser: this.logout,
            isRole: this.isRole,
            articles: [],
            article: null,
            history: [],
            config: null,
            message: ''
        };
    }

    componentDidMount() {
        loadConfigAPI((data) => {
                this.setState({ config: data })
                this.loadUserArticles(data.firstArticleId);
            },
            () => this.setState({ message: "Config konnte nicht geladen werden!"}));
        this.timerID = -1;
    }

    componentWillUnmount() {
        if(this.timerID !== -1) {
            clearInterval(this.timerID);
            this.timerID = -1;
        }
    }

    refreshSession = () => {
        refreshSessionAPI(this.state.user.token, () => {}, () => this.handleLogout(), () => this.handleLogout());
    }

    handleLogin = () => {
        this.loadAllArticles();
        this.timerID = setInterval(this.refreshSession, 120000);
    }

    handleLogout = () => {
        clearInterval(this.timerID);
        this.timerID = -1;
        this.state.logoutUser();
        this.setState({
            message: "Du wurdest abgemeldet"
        });
        this.loadUserArticles(this.state.config.firstArticleId);
        logoutAPI(this.state.user.token);
    }

    handleClearMessage = () => {
        this.setState({
            message: ''
        })
    }

    handleChangeConfig = (config) => {
        this.setState({
            config: config
        })
    }

    activateArticle = (article) => {
        this.setState({
            article: article
        })
    }

    handleDeleteArticle = (id) => {
        let articles = [...this.state.articles];
        for( var i = 0; i < articles.length; i++) {
            if ( articles[i].id === id) {
                articles.splice(i, 1);
                this.setState({
                    articles: articles,
                    article: articles[0],
                    history: []
                });
                return;
            }
        }
    }

    loadUserArticles(firstArticleId) {
        loadArticlesAPI((articles) => {
                this.setState({ articles: articles });
                for(const article of articles) {
                    if(article.id === firstArticleId) {
                        this.activateArticle(article);
                        return;
                    }
                }
            },
            () => { this.setState({ message: 'Die Artikel konnten nicht geladen werden!'}) });
    }

    loadAllArticles() {
        loadAllArticlesAPI(this.state.user.token,
            (data) => { this.setState({ articles: data }) },
            () => {
                this.handleLogout()
                this.setState({ message: 'Die Artikel konnten nicht geladen werden!'})
            });
    }

    handleAddArticle = () => {
        addArticleAPI(this.state.user.token,
            (article) => {
                this.setState({ articles: [...this.state.articles].concat(article) });
                this.activateArticle(article);
            },
            () => this.handleLogout());
    }

    updateArticlesFromArticle = (article) => {
        let articles = [...this.state.articles];
        for(let i=0; i<articles.length; i++) {
            if(articles[i].id === article.id) {
                articles[i] = article;
                break;
            }
        }
        this.setState({
            article: article,
            articles: articles
        })
    }

    render() {
        return (
            <div id="wrapper">
                <UserContext.Provider value={this.state}>
                    <Header
                        message={this.state.message}
                        onClearMessage={this.handleClearMessage}
                        onOpenProfile={this.handleOpenProfile}
                        onLogin={this.handleLogin}
                        onLogout={this.handleLogout} />
                    { this.state.article !== null &&
                        <Article
                            key={this.state.article.id}
                            article={this.state.article}
                            mainArticleId={this.state.config.firstArticleId}
                            onLogout={this.handleLogout}
                            onDeleteArticle={this.handleDeleteArticle}
                            onChangeConfig={this.handleChangeConfig}
                            onChangeArticle={this.updateArticlesFromArticle}
                            onShowMessage={(message) => {this.setState({ message: message })}} />}
                    <Sidebar
                        articles={this.state.articles}
                        onClickArticle={this.activateArticle}
                        onLogout={this.handleLogout}
                        onAddArticle={this.handleAddArticle} />
                </UserContext.Provider>
            </div>
        );
      }
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

document.body.classList.add("is-preload");
