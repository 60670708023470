import React from 'react';
import Message from './message';
import {UserContext, Role} from '../context/user-context';
import LoginPopup from './loginPopup';
import ProfilePopup from './profilePopup';
import {images, avatarimages} from '../config/constants';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoginPopup: false,
            showProfilePopup: false
        }
    }
    
    showLoginPopup = () => {
        this.setState({
            showLoginPopup: true
        })
    }

    hideLoginPopup = () => {
        this.setState({
            showLoginPopup: false
        });
    }

    handleMessageTimeout = () => {
        this.props.onClearMessage();
    }

    showProfilePopup = () => {
        this.setState({
            showProfilePopup: true
        })
    }
    hideProfilePopup = () => {
        this.setState({
            showProfilePopup: false
        })
    }

    render() {
        return (
            <UserContext.Consumer>
                { (contextState) => (

            <header id="header">
                <h1><a href="index.html"><img src={images('./frag-dich-logo.png')} alt="Frag-Dich Logo" />Frag Dich</a></h1>
                { this.props.message !== "" &&
                <Message
                    message={this.props.message}
                    timeout="10000"
                    onTimeout={this.handleMessageTimeout} />}
                <nav className="main">
                    <ul>
                        <li className={this.context.isRole(Role.user) ? "login" : "profile"}>
                            { this.context.isRole(Role.user) &&
                                <button onClick={this.showLoginPopup}>Anmelden</button> }
                            { this.context.isRole(Role.author) &&
                                <button onClick={this.showProfilePopup}>
                                    <span className="author">
                                        <span className="name">{this.context.user.name}</span>
                                        <img src={avatarimages('./avatar'+this.context.user.id+'.jpg')} alt="" />
                                    </span>
                                </button>}
                        </li>
                    </ul>
                </nav>
                <LoginPopup
                        show={this.state.showLoginPopup}
                        onClose={this.hideLoginPopup}
                        onLogin={this.props.onLogin}/>
                <ProfilePopup
                    show={this.state.showProfilePopup}
                    onClose={this.hideProfilePopup}
                    onLogout={this.props.onLogout}/>
            </header>
            )}
            </UserContext.Consumer>
        );
    }
}
Header.contextType = UserContext;
