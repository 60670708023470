import React from 'react';
import Switch from '@material-ui/core/Switch';

/**
 * Component ArticleAdminHeader
 * 
 * Properties:
 * article:            The article object
 * mainArticleId:      The id of the main article
 * onPublishUnpublish: Event handler for changing the state of the article
 * onSetMainArticle:   Event handler for setting the main article
 * onEdit:             Event handler for starting testing the article
 */
export default class ArticleAdminHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articleStatus: this.props.article.status === this.articleStatus.published,
            mainArticle: this.props.mainArticleId === this.props.article.id
        };
    }

    articleStatus = {
        draft: 0,
        published: 1
    }

    handleChangeArticleStatus = () => {
        let nextArticleStatus = !this.state.articleStatus;
        this.setState({
            articleStatus: nextArticleStatus
        })
        this.props.onPublishUnpublish(nextArticleStatus ? this.articleStatus.published : this.articleStatus.draft);
    };

    handleChangeArticleEdit = () => {
        this.props.onEdit(!this.props.edit);
    };

    handleSetMainArticle = () => {
        let mainArticle = !this.state.mainArticle;
        this.setState({
            mainArticle: mainArticle
        })
        this.props.onSetMainArticle(this.props.article.id);
    }

    render() {
        return(
            <div className="post-admin-header">
                <div>Veröffentlicht:
                    <Switch
                        checked={this.state.articleStatus}
                        onClick={this.handleChangeArticleStatus}
                        name="status"
                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                </div>
                <div>Bearbeitungsmodus:
                    <Switch
                        checked={this.props.edit}
                        onClick={this.handleChangeArticleEdit}
                        name="editStatus"
                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                </div>
                {this.state.articleStatus &&
                <div>Hauptartikel:
                <Switch
                        checked={this.state.mainArticle}
                        onClick={this.handleSetMainArticle}
                        name="main-article"
                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                </div>}
            </div>
        )};
}
