import React from 'react';
import {UserContext} from '../context/user-context';
import {getAllQuestionsWithAnswersFromArticleAPI} from '../api/adminApi';

export default class QuestionAnswerTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
        }
        this.handleExpandNodeChange = this.handleExpandNodeChange.bind(this);
    }

    componentDidMount() {
        this.loadTree();
    }

    loadTree() {
        getAllQuestionsWithAnswersFromArticleAPI(this.props.article.id, this.context.user.token,
            (data) => {
                let tree = this.orderTree(data);
                if(tree.length > 0) {
                    this.setState({
                        treeData: tree
                    })
                }
            },
            () => this.props.onLogout());
    }

    orderTree(questions) {
        let tree = [];
        let lookup = [];
        // Lookup erstellen
        for(let idx = 0; idx < questions.length; idx++) {
            lookup[''+questions[idx].id] = idx;
            questions[idx].used = false;
        }
        // Find first question
        if(this.props.article.first_question_id !== null) {
            let firstQuestionIdx = lookup[''+this.props.article.first_question_id];
            let question = questions[firstQuestionIdx];
            question.used = true;
            tree[0] = {
                answerId: -1,
                questionId: question.id,
                answer: '',
                question: question.question,
                children: [],
                collapse: false
            }
            this.walkAnswers(tree[0], question, questions, lookup);
        }
        // Attach unattached questions
        for(let question of questions) {
            if(question.used === false) {
                let headQuestion = {
                    questionId: question.id,
                    question: question.question,
                    answerId: -1,
                    answer: '',
                    used: false,
                    collapse: false,
                    children: []
                };
                for(let answer of question.answers) {
                    let answerQuestion = {
                        questionId: -1,
                        question: '',
                        answerId: answer.id,
                        answer: answer.answer,
                        used: true,
                        collapse: false,
                        children: []
                    };
                    if(answer.leads_to_question_id !== null) {
                        answerQuestion.questionId = answer.leads_to_question_id;
                        answerQuestion.question = questions[''+lookup[answer.leads_to_question_id]].question;
                    }
                    headQuestion.children = headQuestion.children.concat(answerQuestion);
                }
                tree = tree.concat(headQuestion);
            }
        }
        return tree;
    }

    walkAnswers(currentNode, question, questions, lookup) {
        for(let answer of question.answers) {
            this.addAnswerToTree(currentNode, answer, questions, lookup);
        }
    }

    addAnswerToTree(currentNode, answer, questions, lookup) {
        let answerQuestion = {
            answerId: answer.id,
            answer: answer.answer,
            used: false,
            collapse: false,
            children: []
        };
        if(answer.leads_to_question_id !== null) {
            let question = questions[lookup[''+answer.leads_to_question_id]];
            if(question !== null) {
                currentNode.children = currentNode.children.concat(answerQuestion);
                answerQuestion.questionId = question.id;
                answerQuestion.question = question.question;
                answerQuestion.used = question.used;
                question.used = true;
                if(!answerQuestion.used) {
                    this.walkAnswers(answerQuestion, question, questions, lookup);
                }
            }
        } else {
            answerQuestion.questionId = -1;
            answerQuestion.question = "";
            currentNode.children = currentNode.children.concat(answerQuestion);
        }
    }

    handleExpandAll = () => {
        this.collapseOrExpandeAll(false)
    }

    handleCollapseAll = () => {
        this.collapseOrExpandeAll(true)
    }

    handleReload = () => {
        this.loadTree();
    }

    collapseOrExpandeAll(collapse) {
        const data = [...this.state.treeData];
        for(let node of data) {
            this.changeCollapseOnAllNodes(node, collapse);
        }
        this.setState({
            treeData: data
        });
    }

    handleExpandNodeChange(nodeToChange) {
        const data = [...this.state.treeData];
        for(let node of data) {
            this.changeCollapseOnNode(node, nodeToChange)
        }
        this.setState({
            treeData: data
        })
    }

    changeCollapseOnNode(data, node) {
        if(node.answerId === data.answerId && node.questionId === data.questionId) {
            data.collapse = !data.collapse;
            return true;
        }
        for(let child of data.children) {
            if(this.changeCollapseOnNode(child, node)) {
                return true;
            }
        }
        return false;
    }

    changeCollapseOnAllNodes(data, collapse) {
        data.collapse = collapse;
        for(let child of data.children) {
            this.changeCollapseOnAllNodes(child, collapse);
        }
    }

    render() {
        console.log(this.state.treeData)
        return (
            <div className="question-answer-tree">
                { this.state.treeData !== null &&
                <>
                    <div className='control-panel'>
                        <button onClick={this.handleExpandAll} >Alle aufklappen</button>
                        <button onClick={this.handleCollapseAll} >Alle zuklappen</button>
                        <button onClick={this.handleReload} >Aktualisieren</button>
                    </div>
                    <div className="tree">
                        {this.state.treeData.length > 0 && this.props.article.first_question_id !== null &&
                            <div className="question-answer-article-attached">Artikel</div>}
                        {this.state.treeData.length > 0 &&
                            this.state.treeData.map((node) => 
                                <div key={node.questionId} className="question-answer-partial-tree">
                                    { this.renderQuestionAnswerNode(null, node) }
                                </div>
                            )}
                    </div>
                </>} 
            </div>
        )
    }

    renderQuestionAnswerNode(parent, node) {
        return (
            <QuestionAnswerNode
                key={node.answerId + '|' + node.questionId}
                parent={parent}
                node={node}
                collapsed={node.collapse}
                onSetQuestion={this.props.onSetQuestion}
                onClick={() => {this.handleExpandNodeChange(node)}}>
                    {node.children.map(child => this.renderQuestionAnswerNode(node, child))}
            </QuestionAnswerNode>
        )
    }
}

class QuestionAnswerNode extends React.Component {
    render() {
        console.log(this.props.node);
        const questionAnswerClass = "question-answer " + (this.props.node.questionId !== -1 ? (this.props.node.answerId !== -1 ? "regular" : "start") : "end");
        const answerClass   = "answer"
                            + (this.props.node.questionId !== -1 ? " with-question" : " without-question");
        const questionClass = "question"
                            + (this.props.children.length > 0 ? " with-answers" : " without-answers")
                            + (this.props.node.used ? " copy" : " orig");
        const arrowClass = "item-arrow " + (this.props.collapsed ? "collapsed" : "expanded");
        return(
            <div className={'question-answer-node '}>
                <div className={'item '}>
                    <span className={questionAnswerClass}>
                        { this.props.node.answerId !== -1 &&
                            <ul>
                                <li>
                                    <span
                                        className={answerClass}
                                        onClick={() => this.props.onSetQuestion(this.props.parent.questionId)}>
                                        { this.props.node.answer }
                                    </span>
                                </li>
                            </ul> }
                        { this.props.node.questionId !== -1 &&
                            <span className={questionClass}>
                                { this.props.children.length > 0 && <div className={arrowClass} onClick={this.props.onClick} />}
                                { this.props.children.length === 0 && <div className="no-item-arrow" />}
                                <span onClick={() => this.props.onSetQuestion(this.props.node.questionId)}>{ this.props.node.question }</span>
                            </span>}
                    </span>
                </div>
                <div className="item-childs">
                    {this.props.collapsed ? null : this.props.children}
                </div>
            </div>
        )
    }
}
QuestionAnswerTree.contextType = UserContext;